import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

// import Logo from "@components/common/logo";
import Footer from "@components/common/footer";
import NavBar from "@components/common/navBar";
// import Article from "@components/article";
import Works from "@components/experience/work";
// import AllProjects from "@components/projects/all";

import INFO from "@data/user";
// import myArticles from "@data/articles";
import SEO from "@data/seo";

import "../styles/homepage.css";

// import profile from "@assets/profile.jpg";
import Schools from "@components/education/schools";
import Skills from "@components/skill";
import LogoIcon from "@components/common/svgLogo";

const Homepage = () => {
  const [stayLogo, setStayLogo] = useState(false);
  const [logoSize, setLogoSize] = useState(80);
  const [oldLogoSize, setOldLogoSize] = useState(80);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = Math.round(2);

      const newLogoSize = 80 - (scroll * 4) / 10;

      if (newLogoSize < oldLogoSize) {
        if (newLogoSize > 40) {
          setLogoSize(newLogoSize);
          setOldLogoSize(newLogoSize);
          setStayLogo(false);
        } else {
          setStayLogo(true);
        }
      } else {
        setLogoSize(newLogoSize);
        setStayLogo(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [logoSize, oldLogoSize]);

  const currentSEO = SEO.find((item) => item.page === "home");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const logoStyle = {
    display: "flex",
    // position: stayLogo ? "fixed" : "relative",
    top: stayLogo ? "3vh" : "auto",
    zIndex: 999,
    border: stayLogo ? "1px solid white" : "none",
    borderRadius: stayLogo ? "50%" : "none",
    boxShadow: stayLogo ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
  };

  return (
    <>
      <Helmet>
        <title>{INFO.main.title}</title>
        <meta name="description" content={currentSEO?.description} />
        <meta name="keywords" content={currentSEO?.keywords.join(", ")} />
      </Helmet>

      <div className="page-content md:flex md:flex-col self-justify md:w-[63%]">
        <NavBar active="home" />
        <div id="stars" />
        <div id="stars2" />
        <div id="stars3" />
        <div className="content-wrapper">
          {/* <div className="homepage-logo-container">
            <div style={logoStyle}>
              <Logo width={logoSize.toString()} link={false} />
            </div>
  </div> */}

          <div className="homepage-container">
            <div className="homepage-first-area flex gap-y-[100px]">
              <div className="homepage-first-area-left-side">
                <div className="title homepage-title">
                  {INFO.homepage.title}
                </div>

                <div className="subtitle homepage-subtitle">
                  {INFO.homepage.description}
                </div>
              </div>

              <div className="homepage-first-area-right-side mt-[0px]">
                <div className="homepage-image-container">
                  <div className="homepage-image-wrapper shadow-md">
                    <LogoIcon className="homepage-image" />
                    {/* <img src={profile} alt="about" className="homepage-image" /> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="homepage-socials">
              <a href={INFO.socials.github} target="_blank" rel="noreferrer">
                <FontAwesomeIcon
                  icon={faGithub}
                  className="homepage-social-icon"
                />
              </a>
              <a
                href={`mailto:${INFO.main.email}`}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faMailBulk}
                  className="homepage-social-icon"
                />
              </a>
              <a href={INFO.socials.linkedin} target="_blank" rel="noreferrer">
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="homepage-social-icon"
                />
              </a>
            </div>
            {/*  <div className="homepage-projects">
              <AllProjects />
            </div> */}

            <div className="flex justify-between flex-wrap gap-y-20 items-between pt-20">
              <div className="md:w-[450px] w-[100%]">
                <Works />
              </div>
              <div className="md:w-[470px] w-[100%]">
                <Schools />
              </div>
              {/* <div className="homepage-articles">
                 myArticles.map((article, index) => (
                  <div
                    className="homepage-article"
                    key={(index + 1).toString()}
                  >
                    <Article
                      key={(index + 1).toString()}
                      date={article.date}
                      title={article.title}
                      description={article.description}
                      link={`/article/${index + 1}`}
                    />
                  </div>
                )) 
                
              </div> */}
            </div>
            <div className="md:w-[80%] sm:w-[100%] pt-20">
              <Skills />
            </div>

            <div className="page-footer">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
