/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import styled from "styled-components";

const NotFound = () => {
  return (
    <div
      style={{
        // background: "red",
        alignSelf: "stretch",
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Block>
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          viewBox="0 0 24 24"
          height="2em"
          width="20em"
          color="rgb(0, 28, 85)"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <path d="M3 7v4a1 1 0 001 1h3M7 7v10M10 8v8a1 1 0 001 1h2a1 1 0 001-1V8a1 1 0 00-1-1h-2a1 1 0 00-1 1zM17 7v4a1 1 0 001 1h3M21 7v10" />
        </svg>
        <div style={{ height: "100px" }}>
          <p style={{ color: "black", fontSize: "30px", fontWeight: "bold" }}>
            Something went wrong.
          </p>
          <p style={{ color: "black", fontSize: "15px" }}>
            Sorry, we can&apos;t fin the page you are looking for.
          </p>
        </div>
      </Block>
    </div>
  );
};

export default NotFound;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: 5px 7px 17px -3px rgba(0, 0, 0, 0.47);
  box-shadow: 5px 7px 17px -3px rgba(0, 0, 0, 0.47);
`;
