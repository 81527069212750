import React from "react";
// import logo from "./logo.svg";
import "./app2.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { StyledIconEducation } from "./components/icons";
// import { Home } from "@components/home";
import Urumi from "@components/urumi";
import Homepage from "@page/home";
import Education from "@components/education";
import NotFound from "@components/error/404";
import About from "@components/about";
import Articles from "@components/article/list";
import Contact from "src/pages/contact";
import Projects from "src/pages/project";

function App() {
  return (
    <div className="flex flex-col flex-justify sm:items-center">
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" Component={Homepage} /> {/* 👈 Renders at /app/ */}
          <Route path="/about" Component={About} />
          <Route path="/articles" Component={Articles} />
          <Route path="/contact" Component={Contact} />
          <Route path="/projects" Component={Projects} />
          <Route path="/education" Component={Education} />
          <Route path="/urumi" Component={Urumi} />
          <Route path="*" Component={NotFound} /> {/* 👈 Renders at /app/ */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
