import React from "react";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";

import Card from "@components/common/card";

import "../../styles/work.css";
import INFO from "@data/user";

const Schools = () => {
  return (
    <div>
      <Card
        icon={faGraduationCap}
        title="Education"
        body={
          <div>
            {INFO.schools.map((work) => (
              <div className="school flex pb-10 md:w-[440px]">
                <img src={work.logo} alt="facebook" className="work-image" />
                <div className="flex flex-row md:w-[300px] justify-between	">
                  <div className="flex flex-col justify-center  ">
                    <div className="work-title">{work.title}</div>
                    <div className="work-subtitle">{work.subtitle}</div>
                  </div>
                  <div className="work-duration text-xs flex justify-end items-center md:w-[120px] pr-2">
                    {work.duration}
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
      />
    </div>
  );
};

export default Schools;
