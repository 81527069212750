import React from "react";
import { Link } from "react-router-dom";

import "../../styles/navBar.css";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink } from "@components/navbar/NavbarElements";

const navigation = [
  { name: "Home", href: "/", current: true },
  { name: "About", href: "/about", current: false },
  { name: "Projects", href: "/projects", current: false },
  // { name: "Blog", href: "/articles", current: false },
  // { name: "Urumi", href: "/urumi", current: false },
  { name: "Contact", href: "/contact", current: false },
];

const NavBar = (props: { active: string }) => {
  const { active } = props;

  return (
    <div className="md:nav-container">
      <Disclosure as="div" className="flex self-stretch">
        {({ open }) => (
          <div className="flex-1	flex-stretch justify-between">
            <div className="flex flex-1 flex-row sm:justify-between  sm:items-stretch p-1">
              <div className="flex items-center sm:hidden flex-1">
                <Disclosure.Button className="bg-gray-700 relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
                <div className="w-4/5 text-center">
                  <div className="p-2">
                    <h1 className="text-title font-bold	text-1xl	">𝓐ℳ𝓐𝓓𝓞𝓤 𝓢Ⴘ</h1>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden bg-base animate-disclosure mt-[15px]">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as={NavLink}
                    to={item.href}
                    className="block rounded-sm px-3 py-2 text-left font-bold text-xl text-regular-text	active:text-blue-500 active:underline hover:bg-gray-700 hover:text-regular-tex"
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      <nav className="hidden sm:block flex justify-center items-center navbar">
        <div className="nav-background">
          <ul className="nav-list">
            <li className={active === "home" ? "nav-item active" : "nav-item"}>
              <Link to="/">Home</Link>
            </li>
            <li className={active === "about" ? "nav-item active" : "nav-item"}>
              <Link to="/about">About</Link>
            </li>
            <li
              className={active === "projects" ? "nav-item active" : "nav-item"}
            >
              <Link to="/projects">Projects</Link>
            </li>
            {/* <li
              className={active === "articles" ? "nav-item active" : "nav-item"}
            >
              <Link to="/articles">Articles</Link>
  </li> */}
            <li
              className={active === "contact" ? "nav-item active" : "nav-item"}
            >
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavBar;
