import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles/project.css";
// import { SkillItem } from "@components/skill/item";
import SkillIcon from "@components/icons/skillIcon";
import { faChrome } from "@fortawesome/free-brands-svg-icons";

const Project = (props: {
  logo: string[];
  title: string;
  description: string;
  linkText: string;
  link: string;
  androidLink?: string;
  iosLink?: string;
  websiteLink?: string;
}) => {
  const {
    logo,
    title,
    description,
    linkText,
    link,
    androidLink,
    iosLink,
    websiteLink,
  } = props;

  const format = (text: string) => {
    return text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="project overflow-y-auto overflow-x-hidden">
      <div className="shadow-project">
        <div className="project-container">
          <div className="project-logo h-[30px] grid grid-cols-4 gap-y-2 mb-10">
            {logo.map((item) => (
              <div className="tooltip">
                <SkillIcon skill={item} iconProps={{ height: 30, width: 30 }} />
                <span className="tooltiptext pt-[2px] pb-[2px] text-title font-bold w-[120px]">
                  {format(item)}
                </span>
              </div>
            ))}

            {/* <img src={logo} alt="logo" /> */}
          </div>
          <div className="project-title">{title}</div>
          <div className="project-description text-wrap">{description}</div>
          <div className="flex flex-row justify-between text-xs pt-5 project-link">
            <div className="flex flex-row items-end">
              <div className="project-link-icon">
                <Link to={link}>
                  <FontAwesomeIcon icon={faLink} />
                </Link>
              </div>

              <div className="project-link-text">
                <Link to={link}>{linkText}</Link>
              </div>
            </div>

            <div className="flex flex-row items-end gap-5 justify-between">
              {websiteLink ? (
                <Link to={websiteLink} className="link">
                  <div className="tooltip">
                    <FontAwesomeIcon icon={faChrome} className="h-5 w-5" />
                    <span className="tooltiptext w-[60px] text-title font-bold">
                      {format("Site")}
                    </span>
                  </div>
                </Link>
              ) : null}
              {iosLink ? (
                <Link to={iosLink} className="link">
                  <div className="tooltip">
                    <SkillIcon
                      skill="ios"
                      iconProps={{ height: 20, width: 20 }}
                    />
                    <span className="tooltiptext w-[60px] text-title font-bold">
                      {format("Ios")}
                    </span>
                  </div>
                </Link>
              ) : null}

              {androidLink ? (
                <Link to={androidLink} className="link">
                  <div className="tooltip">
                    <SkillIcon
                      skill="android"
                      iconProps={{ height: 20, width: 20 }}
                    />
                    <span className="tooltiptext ml-[-20px] w-[70px] text-title font-bold">
                      {format("Android")}
                    </span>
                  </div>
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;

Project.defaultProps = {
  androidLink: null,
  iosLink: null,
  websiteLink: null,
};
