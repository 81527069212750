import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "@components/common/navBar";
import Footer from "@components/common/footer";
import Logo from "@components/common/logo";
import Article from "@components/article";

import INFO from "@data/user";
import SEO from "@data/seo";
import myArticles from "@data/articles";

import "../../styles/article.css";

const Articles = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentSEO = SEO.find((item) => item.page === "articles");

  return (
    <>
      <Helmet>
        <title>{`Articles | ${INFO.main.title}`}</title>
        <meta name="description" content={currentSEO?.description} />
        <meta name="keywords" content={currentSEO?.keywords.join(", ")} />
      </Helmet>

      <div className="page-content">
        <NavBar active="articles" />
        <div className="content-wrapper">
          <div className="flex justify-start">
            <div className="flex border border-white border-solid border-[1px]	shadow-logo	 rounded-logo justify-center">
              <Logo width="46" />
            </div>
          </div>

          <div className="flex flex-col h-full m-0 pt-32">
            <div className="title articles-title">{INFO.articles.title}</div>

            <div className="subtitle articles-subtitle">
              {INFO.articles.description}
            </div>

            <div className="flex flex-col">
              <div className="pt-12 pb-12 flex flex-col">
                {myArticles.map((article, index) => (
                  <div
                    className="articles-article"
                    key={(index + 1).toString()}
                  >
                    <Article
                      key={(index + 1).toString()}
                      date={article.date}
                      title={article.title}
                      description={article.description}
                      link={`/article/${index + 1}`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="page-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Articles;
