import React, { ReactNode } from "react";

// eslint-disable-next-line import/prefer-default-export
export const SkillItem = ({
  title,
  className,
  icon,
}: {
  title: string;
  className?: string;
  icon: ReactNode;
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <div
      className={`shadow-logo border rounded-lg border-inherit	 items-center justify-start max-h-[40px] flex p-2 max-w-[220px] ${className}`}
    >
      {icon && <div className="min-w-[30px]">{icon}</div>}
      <div className="text-title ml-2 font-medium font-aubrey">
        <h4>{title}</h4>
      </div>
    </div>
  );
};

SkillItem.defaultProps = {
  className: "",
};
