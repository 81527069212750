import React from "react";

function LogoIcon({ className }: { className?: string }) {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="655"
        height="413.68"
        data-name="Layer 1"
        viewBox="0 0 655 413.68"
      >
        <path
          fill="#e6e6e6"
          d="M416.563 129.423a122.041 122.041 0 0110.1-38.517q2.28-5.093 5.019-9.961a.744.744 0 00-1.284-.75 123.729 123.729 0 00-13.768 37.982q-1.033 5.583-1.553 11.246c-.089.952 1.398.946 1.486 0z"
        />
        <circle cx="435.282" cy="73.914" r="9.414" fill="#e6e6e6" />
        <path
          fill="#e6e6e6"
          d="M417.526 129.673a79.174 79.174 0 016.553-24.988q1.479-3.304 3.255-6.462a.483.483 0 00-.833-.487 80.268 80.268 0 00-8.931 24.641q-.67 3.622-1.008 7.296c-.058.618.907.614.964 0z"
        />
        <circle cx="429.67" cy="93.662" r="6.108" fill="#e6e6e6" />
        <path
          fill="#e6e6e6"
          d="M416.309 129.096a79.174 79.174 0 01-10.203-23.732q-.865-3.515-1.407-7.098a.483.483 0 00-.956.128 80.268 80.268 0 008.113 24.923q1.7 3.268 3.692 6.372c.335.523 1.094-.074.76-.593z"
        />
        <circle cx="403.735" cy="93.233" r="6.108" fill="#e6e6e6" />
        <path
          fill="#f2f2f2"
          d="M397.56 134.5v-12a4.505 4.505 0 014.5-4.5h28a4.505 4.505 0 014.5 4.5v12a4.505 4.505 0 01-4.5 4.5h-28a4.505 4.505 0 01-4.5-4.5zM429.06 58h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M413.059 9.5H433.059V13.5H413.059z" />
        <path fill="#ccc" d="M413.059 40.5H433.059V44.5H413.059z" />
        <path
          fill="#f2f2f2"
          d="M459.06 58h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M443.059 9.5H463.059V13.5H443.059z" />
        <path fill="#ccc" d="M443.059 40.5H463.059V44.5H443.059z" />
        <path
          fill="#f2f2f2"
          d="M489.06 58h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M473.059 9.5H493.059V13.5H473.059z" />
        <path fill="#ccc" d="M473.059 40.5H493.059V44.5H473.059z" />
        <path
          fill="#f2f2f2"
          d="M505.06 139h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M489.059 90.5H509.059V94.5H489.059z" />
        <path fill="#ccc" d="M489.059 121.5H509.059V125.5H489.059z" />
        <path
          fill="#f2f2f2"
          d="M535.06 139h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M519.059 90.5H539.059V94.5H519.059z" />
        <path fill="#ccc" d="M519.059 121.5H539.059V125.5H519.059z" />
        <path
          fill="#f2f2f2"
          d="M565.06 139h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M549.059 90.5H569.059V94.5H549.059z" />
        <path fill="#ccc" d="M549.059 121.5H569.059V125.5H549.059z" />
        <path
          fill="#f2f2f2"
          d="M426.06 220h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M410.059 171.5H430.059V175.5H410.059z" />
        <path fill="#ccc" d="M410.059 202.5H430.059V206.5H410.059z" />
        <path
          fill="#f2f2f2"
          d="M456.06 220h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M440.059 171.5H460.059V175.5H440.059z" />
        <path fill="#ccc" d="M440.059 202.5H460.059V206.5H440.059z" />
        <path
          fill="#f2f2f2"
          d="M486.06 220h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M470.059 171.5H490.059V175.5H470.059z" />
        <path fill="#ccc" d="M470.059 202.5H490.059V206.5H470.059z" />
        <path
          fill="#f2f2f2"
          d="M519.06 58h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M503.059 9.5H523.059V13.5H503.059z" />
        <path fill="#ccc" d="M503.059 40.5H523.059V44.5H503.059z" />
        <path
          fill="#f2f2f2"
          d="M549.06 58h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M533.059 9.5H553.059V13.5H533.059z" />
        <path fill="#ccc" d="M533.059 40.5H553.059V44.5H533.059z" />
        <path
          fill="#f2f2f2"
          d="M579.06 58h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M563.059 9.5H583.059V13.5H563.059z" />
        <path fill="#ccc" d="M563.059 40.5H583.059V44.5H563.059z" />
        <path
          fill="#f2f2f2"
          d="M609.06 58h-12a4.505 4.505 0 01-4.5-4.5v-49a4.505 4.505 0 014.5-4.5h12a4.505 4.505 0 014.5 4.5v49a4.505 4.505 0 01-4.5 4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#ccc" d="M593.059 9.5H613.059V13.5H593.059z" />
        <path fill="#ccc" d="M593.059 40.5H613.059V44.5H593.059z" />
        <path
          fill="#ccc"
          d="M654 59.5H369a1 1 0 010-2h285a1 1 0 010 2zM654 140.5H369a1 1 0 010-2h285a1 1 0 010 2zM654 221.5H369a1 1 0 010-2h285a1 1 0 010 2z"
        />
        <path
          fill="#e6e6e6"
          d="M581.563 210.423a122.041 122.041 0 0110.1-38.517q2.28-5.093 5.019-9.961a.744.744 0 00-1.284-.75 123.729 123.729 0 00-13.768 37.982q-1.033 5.583-1.553 11.246c-.089.952 1.398.946 1.486 0z"
        />
        <circle cx="600.282" cy="154.914" r="9.414" fill="#e6e6e6" />
        <path
          fill="#e6e6e6"
          d="M582.526 210.673a79.174 79.174 0 016.553-24.988q1.479-3.304 3.255-6.462a.483.483 0 00-.833-.487 80.268 80.268 0 00-8.931 24.641q-.67 3.622-1.008 7.296c-.058.618.907.614.964 0z"
        />
        <circle cx="594.67" cy="174.662" r="6.108" fill="#e6e6e6" />
        <path
          fill="#e6e6e6"
          d="M581.309 210.096a79.174 79.174 0 01-10.203-23.732q-.865-3.515-1.407-7.098a.483.483 0 00-.956.128 80.268 80.268 0 008.113 24.923q1.7 3.268 3.692 6.372c.335.523 1.094-.074.76-.593z"
        />
        <circle cx="568.735" cy="174.233" r="6.108" fill="#e6e6e6" />
        <path
          fill="#f2f2f2"
          d="M562.56 215.5v-12a4.505 4.505 0 014.5-4.5h28a4.505 4.505 0 014.5 4.5v12a4.505 4.505 0 01-4.5 4.5h-28a4.505 4.505 0 01-4.5-4.5z"
          style={{ isolation: "isolate" }}
        />
        <path fill="#3f3d56" d="M382 363.5H1a1 1 0 010-2h381a1 1 0 010 2z" />
        <circle cx="199.059" cy="205.5" r="27" fill="#2f2e41" />
        <path
          fill="#ffb8b8"
          d="M173.405 378.826L172.69 366.588 219.556 358.003 220.612 376.067 173.405 378.826z"
        />
        <path
          fill="#2f2e41"
          d="M175.675 357.641l1.38 23.604-14.861.868-2.249-38.465a14.887 14.887 0 0115.73 13.993z"
        />
        <path
          fill="#ffb8b8"
          d="M193.789 378.714L186.004 388.185 145.768 362.666 157.257 348.688 193.789 378.714z"
        />
        <path
          fill="#2f2e41"
          d="M177.737 392.726l15.014-18.266 11.5 9.453-24.466 29.766a14.887 14.887 0 01-2.048-20.953zM171.387 334.67l-5.32 25.079a4 4 0 003.848 4.83l52.298.843a4 4 0 004.004-4.695l-4.575-25.922a4 4 0 00-3.939-3.305H175.3a4 4 0 00-3.913 3.17z"
        />
        <path
          fill="#2f2e41"
          d="M180.06 343.5s-51-32-61-15c-5 8.5-2.5 16.75 1.25 22.875a34.63 34.63 0 0014.402 12.891l41.347 20.234 15-16-10-9zM206.06 338.5s66-19 53 17-62 22-62 22l-5-15 17-12z"
        />
        <circle cx="199.398" cy="211.665" r="24.561" fill="#ffb8b8" />
        <path
          fill="#ccc"
          d="M200.685 342.469a131.994 131.994 0 01-30.258-3.987l-.292-.08-13.706-63.045a23.225 23.225 0 0114.933-26.943 80.46 80.46 0 0151.3-1.024 23.47 23.47 0 0115.635 27.764L222.99 338.35l-.216.102c-6.362 3.014-14.317 4.017-22.088 4.017z"
        />
        <path
          fill="#ffb8b8"
          d="M182.274 347.366a10.056 10.056 0 0114.476-5.312l26.986-23.424 2.442 18.408-26.001 19.422a10.11 10.11 0 01-17.903-9.094z"
        />
        <path
          fill="#ccc"
          d="M208.012 352.476a4.534 4.534 0 01-1.296-.19 4.453 4.453 0 01-2.963-2.817l-3.257-9.229a4.472 4.472 0 011.302-4.902l36.77-22.074-14.495-64.661.369-.09c.15-.036 15-3.416 21.085 12.811 4.262 11.365 20.032 36.077 18.92 52.777a14.885 14.885 0 01-12.323 13.727l-41.388 23.713a4.456 4.456 0 01-2.724.935z"
        />
        <path
          fill="#ffb8b8"
          d="M212.136 345.173a10.056 10.056 0 00-14.475-5.313l-26.987-23.424-2.441 18.408 26 19.423a10.11 10.11 0 0017.903-9.094z"
        />
        <path
          fill="#ccc"
          d="M189.106 352.476a4.456 4.456 0 01-2.724-.935l-44.388-21.713a14.885 14.885 0 01-12.322-13.727c-1.113-16.7 17.657-43.412 21.919-54.777 6.085-16.227 20.935-12.848 21.086-12.81l.368.089-17.494 66.66 39.769 20.075a4.472 4.472 0 011.302 4.902l-3.257 9.229a4.453 4.453 0 01-2.963 2.816 4.535 4.535 0 01-1.296.19z"
        />
        <path
          fill="#6c63ff"
          d="M242.06 362.5h-89a7.008 7.008 0 01-7-7v-49a7.008 7.008 0 017-7h89a7.008 7.008 0 017 7v49a7.008 7.008 0 01-7 7z"
        />
        <circle cx="197.559" cy="331" r="6" fill="#fff" />
        <path
          fill="#2f2e41"
          d="M174.59 210.666c-1.455-3.693.933-8.093 4.476-9.881s7.819-1.449 11.609-.27c3.184.99 6.17 2.526 9.276 3.739a20.762 20.762 0 009.753 1.736c3.315-.374 6.613-2.232 7.97-5.28 1.405-3.158.449-7.013-1.716-9.708a18.8 18.8 0 00-8.612-5.535c-7.307-2.575-15.748-2.65-22.532 1.092s-11.394 11.71-9.992 19.33"
        />
        <path
          fill="#e6e6e6"
          d="M32.662 351.818a122.042 122.042 0 0110.101-38.517q2.28-5.092 5.018-9.96a.744.744 0 00-1.283-.75 123.728 123.728 0 00-13.768 37.982q-1.034 5.583-1.554 11.245c-.088.953 1.399.947 1.486 0z"
        />
        <circle cx="51.381" cy="296.31" r="9.414" fill="#6c63ff" />
        <path
          fill="#e6e6e6"
          d="M33.625 352.069a79.174 79.174 0 016.553-24.988q1.48-3.304 3.256-6.462a.483.483 0 00-.833-.487 80.269 80.269 0 00-8.932 24.64q-.67 3.623-1.008 7.297c-.057.617.908.613.965 0z"
        />
        <circle cx="45.769" cy="316.058" r="6.108" fill="#6c63ff" />
        <path
          fill="#e6e6e6"
          d="M32.408 351.492a79.174 79.174 0 01-10.202-23.733q-.866-3.514-1.408-7.097a.483.483 0 00-.956.128 80.268 80.268 0 008.113 24.923q1.7 3.268 3.693 6.372c.335.522 1.093-.074.76-.593z"
        />
        <circle cx="19.835" cy="315.629" r="6.108" fill="#6c63ff" />
        <path
          fill="#f2f2f2"
          d="M13.659 356.896v-12a4.505 4.505 0 014.5-4.5h28a4.505 4.505 0 014.5 4.5v12a4.505 4.505 0 01-4.5 4.5h-28a4.505 4.505 0 01-4.5-4.5z"
          style={{ isolation: "isolate" }}
        />
        <path
          fill="#ff6584"
          d="M314.409 370.9c0 6.578-5.332 9.567-11.909 9.567s-11.909-2.99-11.909-9.566 2.647-14.252 11.909-11.605c9.262-3.97 11.909 5.028 11.909 11.605z"
        />
        <path
          fill="#3f3d56"
          d="M301.92 362.377a1.904 1.904 0 01-1.891-1.66c-.394-3.28-2.568-9.707-3.484-12.318a1.703 1.703 0 01.22-1.567 1.787 1.787 0 012.663-.192 1.705 1.705 0 01.469 1.276c-.18 3.35 2.263 8.765 3.735 11.694a1.908 1.908 0 01-1.51 2.756 1.763 1.763 0 01-.202.01z"
        />
        <path
          fill="#3f3d56"
          d="M310.732 354.985c4.134-3.383.89-10.307-4.283-8.978a4.178 4.178 0 00-1.864.994c-3.174 2.998-3.666 10.67-3.666 10.67s5.605.759 9.813-2.686z"
        />
      </svg>
    </div>
  );
}

export default LogoIcon;

LogoIcon.defaultProps = {
  className: "",
};
