import React from "react";
import { Link } from "react-router-dom";

import INFO from "@data/user";

import "../../styles/logo.css";

const Logo = (props: { width: string; link?: boolean }) => {
  const { width, link } = props;

  const imageElement = (
    <img src={INFO.main.logo} alt="logo" className="logo" width={width} />
  );

  return <div>{link ? <Link to="/">{imageElement}</Link> : imageElement}</div>;
};

export default Logo;

Logo.defaultProps = {
  link: true,
};
