import { FaBars } from "react-icons/fa";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const Nav = styled.nav`
  background: transparent;
  height: 75px;
  display: flex;
  // border-bottom: 0.0001em solid rgb(0, 28, 85);
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  @media screen and (max-width: 818px) {
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
`;

export const NavLink = styled(Link)`
  color: rgb(0, 28, 85);
  background: "red";
  text-decoration: none;
  padding: 0 1rem;
  cursor: pointer;
  font-family: "Aubrey", cursive;
  font-size: 1.25rem; /* 20px */
  line-height: 1.75rem; /* 28px */
  font-weight: 700;
  &.active {
    color: blue;
    text-decoration: underline;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  padding-right: 7%;
`;

export const NavMenuProfile = styled.div`
  display: flex;
  flex-direction: row;
  width: 25%;
  justify-content: center;
  align-items: center;
  /* @media screen and (max-width: 768px) {
    width: 100%;
  } */
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-right: 24px;
  /* @media screen and (max-width: 768px) {
    display: none;
    margin: 0;
  } */
`;

export const NavBtnLink = styled(Link)`
  border-radius: 4px;
  background: #808080;
  padding: 10px 22px;
  color: #000000;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #808080;
  }
`;
