/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import styled from "styled-components";
import avignonuniversite from "../../assets/avignonuniversite.png";
import esp from "../../assets/esp.jpg";

import IconEducation from "../icons";

const Education = () => {
  return (
    <div
      style={{
        flex: 1,
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <IconEducation className="animate-degree" />
      <p
        style={{ color: "rgb(127, 164, 186)" }}
        className="font-extrabold text-5xl py-10 pt-0 animate-degree_title"
      >
        Degrees Received
      </p>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "70%",
          justifyContent: "space-between",
        }}
        className="animate-fade"
      >
        <Block
          style={{
            border: "0.0rem solid white",
            borderColor: "white",
            width: "91%",
          }}
        >
          <div
            style={{
              background: "rgb(127, 164, 186)",
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderRadius: "10px 10px 0px 0px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            <p style={{ fontFamily: "Aubrey, cursive", fontSize: "20px" }}>
              Université d&apos;Avignon et des Pays de Vaucluse,France
            </p>
            <p style={{ fontFamily: "Aubrey, cursive", fontSize: "20px" }}>
              2017 - 2019
            </p>
          </div>
          <div style={{ alignSelf: "stretch", color: "rgb(0, 28, 85)" }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis turpis
            eros, euismod in placerat ullamcorper, pulvinar sit amet ante. Morbi
            in turpis at ex rhoncus pellentesque. Morbi velit purus, sodales ac
            laoreet ac, viverra quis ex. Nulla facilisi. Nulla mollis varius
            felis eu euismod. Suspendisse tortor elit, scelerisque sed tristique
            eu, rutrum id metus. Mauris auctor eleifend sem et lobortis. Aliquam
            erat volutpat.
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <img
              src={avignonuniversite}
              className="Avignon-université-logo"
              style={{ borderRadius: "50%", height: 90 }}
              alt="logo"
            />
            <a
              href="https://univ-avignon.fr/"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "white",
                alignSelf: "flex-end",
              }}
              rel="noreferrer"
            >
              <div
                style={{
                  backgroundColor: "rgb(127, 164, 186)",
                  borderRadius: "5px",
                  padding: "5px",
                  fontSize: "15px",
                }}
              >
                Visit Website
              </div>
            </a>
          </div>
        </Block>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "70%",
          justifyContent: "space-between",
          marginTop: "50px",
        }}
        className="animate-fade"
      >
        <Block
          style={{
            border: "0.0rem solid white",
            borderColor: "white",
            width: "91%",
          }}
        >
          <div
            style={{
              background: "rgb(127, 164, 186)",
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              borderRadius: "10px 10px 0px 0px",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            <p style={{ fontFamily: "Aubrey, cursive", fontSize: "20px" }}>
              Ingénieur,EcoleSupérieure Polytechnique, Mauritania
            </p>
            <p style={{ fontFamily: "Aubrey, cursive", fontSize: "20px" }}>
              2017 - 2019
            </p>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              color: "rgb(0, 28, 85)",
              textAlign: "left",
              padding: "10px",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis turpis
            eros, euismod in placerat ullamcorper, pulvinar sit amet ante. Morbi
            in turpis at ex rhoncus pellentesque. Morbi velit purus, sodales ac
            laoreet ac, viverra quis ex. Nulla facilisi. Nulla mollis varius
            felis eu euismod. Suspendisse tortor elit, scelerisque sed tristique
            eu, rutrum id metus. Mauris auctor eleifend sem et lobortis. Aliquam
            erat volutpat.
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <img
              src={esp}
              className="Avignon-université-logo"
              style={{ borderRadius: "50%", height: 90 }}
              alt="logo"
            />
            <a
              href="https://www.esp.mr/"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "white",
                alignSelf: "flex-end",
              }}
              rel="noreferrer"
            >
              <div
                style={{
                  backgroundColor: "rgb(127, 164, 186)",
                  borderRadius: "5px",
                  padding: "5px",
                  fontSize: "15px",
                }}
              >
                Visit Website
              </div>
            </a>
          </div>
        </Block>
      </div>
    </div>
  );
};

export default Education;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-box-shadow: 5px 7px 17px -3px rgba(0, 0, 0, 0.47);
  box-shadow: 5px 7px 17px -3px rgba(0, 0, 0, 0.47);
  border-radius: 0px 0px 10px 10px; // top-left, top-right, bottom-right, bottom-left
`;
