import React from "react";

import INFO from "@data/user";
import Project from "@components/projects/project";

import "./styles/allProjects.css";

const AllProjects = () => {
  return (
    <div className="all-projects-container">
      {INFO.projects.map((project) => (
        <div className="all-projects-project" key={project.id}>
          <Project
            logo={project.logo}
            title={project.title}
            description={project.description}
            linkText={project.linkText}
            link={project.link}
            androidLink={project?.androidLink}
            iosLink={project?.iosLink}
            websiteLink={project?.websiteLink}
          />
        </div>
      ))}
    </div>
  );
};

export default AllProjects;
