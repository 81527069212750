const ARTICLE1 = {
  date: "7 May 2023",
  title: "The Benefits of Cloud Computing",
  description:
    "Cloud computing offers a range of benefits, including cost savings and increased flexibility. Find out why more businesses are turning to the cloud.",
  keywords: [
    "The Benefits of Cloud Computing",
    "Tharindu",
    "Tharindu N",
    "Tharindu Nayanajith",
  ],
  style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
};

const ARTICLE2 = {
  date: "7 May 2023",
  title: "Artificial Intelligence in Healthcare",
  description:
    "AI is transforming the healthcare industry, from improving patient outcomes to streamlining operations. Discover the latest applications of this game-changing technology.",
  style: ``,
  keywords: [
    "Artificial Intelligence in Healthcare",
    "Tharindu",
    "Tharindu N",
    "Tharindu Nayanajith",
  ],
};

const myArticles = [ARTICLE1, ARTICLE2];

export default myArticles;
