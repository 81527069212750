import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "@components/common/card";

import "../../styles/work.css";
import INFO from "@data/user";

const Works = () => {
  return (
    <div className="works">
      <Card
        icon={faBriefcase}
        title="Work"
        body={
          <div className="works-body">
            {INFO.works.map((work) => (
              <div className="work">
                <img src={work.logo} alt="facebook" className="work-image" />
                <div className="flex flex-row w-[350px] justify-between	">
                  <div className="flex flex-col justify-center  ">
                    <div className="work-title">{work.title}</div>
                    <div className="work-subtitle">{work.subtitle}</div>
                  </div>
                  <div className="work-duration text-xs text-left flex justify-start items-center w-[120px]">
                    {work.duration}
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
      />
    </div>
  );
};

export default Works;
