import logo from "@assets/logo192.png";
import jpaul from "@assets/jpaul.png";
import cpgmn from "@assets/capgemini.png";
import ordisys from "@assets/ordisys.png";
import bcm from "@assets/bcm.png";
import avignonuniversite from "@assets/avignonuniversite.png";
import esp from "@assets/esp.jpg";
// import enedis from "@assets/enedis.png";
// eslint-disable-next-line import/no-webpack-loader-syntax
import end from "@assets/enedis.png";

const INFO = {
  main: {
    title: "Amadou SY",
    name: "Amadou SY.",
    email: "amsada93@gmail.com",
    logo,
  },

  socials: {
    github: "https://github.com/amadousysada/",
    linkedin: "https://www.linkedin.com/in/amadou-sy-979488112/",
  },

  homepage: {
    title: "Software engineer",
    description: `Experienced software engineer with a solid background in software 
    engineering and an impressive track record in designing and 
    creating high-performance APIs, developing innovative solutions, and managing complex projects. 
    Strong skills in Python, Java, and JavaScript, as well as expertise in technologies such as Django, Spring Boot, Scikit-learn, Tensorflow, NLTK,
    Docker, Kubernetes, Ansible, Terraform, Gitlab, React and React Native. I've contributed to large-scale projects, including solving scalability problems for high-profile clients and creating effective solutions to meet business needs. 
    I'm always looking to bring my experience and enthusiasm to  innovative companies and to contribute to its success.

    I enjoy solving complex problems and learning new skills.
    I am passionate about creating high-quality code that follows best practices and industry standards.
  `,
  },

  about: {
    title: "I’m Amadou SY. I live in Paris, where I design the future.",
    description: `I've worked on a variety of projects over the years and I'm proud of the progress I've made. 
    If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. 
    Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.
  `,
  },

  articles: {
    title:
      "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
    description:
      "Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
  },

  projects: [
    {
      id: "1",
      title: "Arena five",
      description: `Arena app is an application that makes it easy for soccer five fans to book their matches and share them with friends and family.`,
      logo: [
        "python",
        "django",
        "graphql",
        "react_native",
        "type_script",
        "expo",
        "docker",
        "elastic search",
      ],
      linkText: "View Project",
      link: "https://github.com/arenafive?tab=repositories",
      websiteLink: "https://site.arenafive.app/support.html",
      iosLink: "https://apps.apple.com/us/app/arena-app/id1644560214",
    },

    {
      id: "2",
      title: "Shams",
      description: `This mobile app is a summary of the rules of the great poet Rûmi.
        The meeting between the poet Rûmi and the Sufi Shams of Tabriz, in 13th-century Iran, gave rise to his 40 rules of love, which can be found in "Soufi mon amour" by Elif Shafak.

        These 40 rules are full of truth and wisdom, hence my motivation to share them in order to spread love in this world that so desperately needs it.
        
        Rules that can guide us in our daily lives and answer our deepest questions.
        
        `,
      logo: ["react_native", "expo"],
      linkText: "View Project",
      link: "https://github.com/amadousysada/shams",
      androidLink:
        "https://play.google.com/store/apps/details?id=com.amadousy.shams",
      iosLink:
        "https://apps.apple.com/us/app/40-r%C3%A8gles-de-shams-de-tabriz/id1659886738",
    },
  ],

  works: [
    {
      id: "1",
      title: "Enedis",
      subtitle: "Senior Software Engineer",
      duration: "Fev 2024 - Present",
      logo: end,
    },
    {
      id: "2",
      title: "John Paul - Accor group",
      subtitle: "Software Developement Engineer",
      duration: "Nov 2021 - Jan 2024",
      logo: jpaul,
    },
    {
      id: "3",
      title: "Capgemini",
      subtitle: "Software Engineer",
      duration: "Aug 2019 - Sept 2021",
      logo: cpgmn,
    },
    {
      id: "4",
      title: "ORDISYS",
      subtitle: "Software Developer",
      duration: "Sep 2018 - Aug 2019",
      logo: ordisys,
    },
    {
      id: "5",
      title: "Central Bank of Mauritania",
      subtitle: "Software Solution Architect",
      duration: "Jan 2017 - Jul 2017",
      logo: bcm,
    },
  ],
  schools: [
    {
      id: "1",
      title: "Université d'Avignon et des Pays de Vaucluse, France",
      subtitle: "Master degree in Software Engineering for Numerical Societies",
      duration: "2017 - 2019",
      logo: avignonuniversite,
      website: "https://univ-avignon.fr/",
    },
    {
      id: "2",
      title: "Ecole Superieure Polytechnique de Nouakchott, Mauritania",
      subtitle: "Master degree in computer science",
      duration: "2012 - 2017",
      logo: esp,
      webiste: "https://www.esp.mr/",
    },
  ],
};

export default INFO;
