// icon:education | Zondicons http://www.zondicons.com | Steve Schoger
import * as React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  color: rgb(127, 164, 186);
`;

function IconEducation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <StyledDiv>
      <svg
        fill="currentColor"
        viewBox="0 -10 20 40"
        height="10em"
        width="20em"
        color="rgb(127, 164, 186)"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        <path d="M3.33 8L10 12l10-6-10-6L0 6h10v2H3.33zM0 8v8l2-2.22V9.2L0 8zm10 12l-5-3-2-1.2v-6l7 4.2 7-4.2v6L10 20z" />
      </svg>
    </StyledDiv>
  );
}

export default IconEducation;
