// icon:education | Zondicons http://www.zondicons.com | Steve Schoger
import SVG from "@data/svg";
import * as React from "react";

function SkillIcon(props: {
  skill: string;
  iconProps?: React.SVGProps<SVGSVGElement>;
}) {
  const { skill, iconProps } = props;
  const SKILL = SVG.find((item) => item.skill === skill);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x={SKILL?.x}
      y={SKILL?.y}
      width="100"
      height="100"
      viewBox={SKILL?.viewBox}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...iconProps}
    >
      {SKILL?.paths.map((item) => item.path)}
    </svg>
  );
}

export default SkillIcon;

SkillIcon.defaultProps = {
  iconProps: null,
};
