import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "@components/common/navBar";
import Footer from "@components/common/footer";
import Logo from "@components/common/logo";
import Socials from "@components/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "../styles/contact.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentSEO = SEO.find((item) => item.page === "contact");

  return (
    <>
      <Helmet>
        <title>{`Contact | ${INFO.main.title}`}</title>
        <meta name="description" content={currentSEO?.description} />
        <meta name="keywords" content={currentSEO?.keywords.join(", ")} />
      </Helmet>

      <div className="page-content">
        <NavBar active="contact" />
        <div className="content-wrapper">
          <div className="hidden contact-logo-container-fake">
            <div className="contact-logo">
              <Logo width="46" />
            </div>
          </div>

          <div className="contact-container">
            <div className="title contact-title">Let&apos;s Get in Touch:</div>

            <div className="subtitle contact-subtitle">
              Thank you for your interest in getting in touch with me. I welcome
              your feedback, questions, and suggestions. If you have a specific
              question or comment, please feel free to email me directly at
              &nbsp;{" "}
              <a style={{ color: "blue" }} href={`mailto:${INFO.main.email}`}>
                {INFO.main.email}
              </a>
              . I make an effort to respond to all messages within 24 hours,
              although it may take me longer during busy periods. Alternatively,
              you can use the contact form on my website to get in touch. Simply
              fill out the required fields and I&apos;ll get back to you as soon
              as possible.
            </div>
          </div>

          <div className="socials-container">
            <div className="contact-socials">
              <Socials />
            </div>
          </div>

          <div className="page-footer">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
