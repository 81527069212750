/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";

import "../../styles/socials.css";
import "../../styles/skill.css";
import Card from "@components/common/card";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { SkillItem } from "@components/skill/item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPython } from "@fortawesome/free-brands-svg-icons";
import SkillIcon from "@components/icons/skillIcon";

const Skills = () => {
  return (
    <div className="works">
      <Card
        icon={faBriefcase}
        title="Skills"
        body={
          <section id="my-resume" className="my-resume px-50 w-50 offset-6">
            <div className="container-xl">
              <div className="row">
                <div className="col-12">
                  <div className="my-resume__inner">
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-12 col-xxl-6 mb-3 mb-md-0">
                        <div className="row">
                          <div className="col-12 col-md-12 py-0 py-md-3">
                            <h3
                              className="heading-default__small opacity is-on"
                              data-viewport="opacity"
                            >
                              Core skills
                            </h3>
                            <div className="grid md:grid-cols-4 md:gap-3 grid-cols-2 mb-10 gap-2 ">
                              <SkillItem
                                title="Python"
                                icon={
                                  <SkillIcon
                                    skill="python"
                                    iconProps={{ height: 30, width: 30 }}
                                  />
                                }
                              />
                              <SkillItem
                                title="Django"
                                icon={
                                  <SkillIcon
                                    skill="django"
                                    iconProps={{ height: 30, width: 50 }}
                                  />
                                }
                              />
                              <SkillItem title="Fast api" icon={null} />
                              {/*
                              <SkillItem
                                title="Java"
                                icon={
                                  <SkillIcon
                                    skill="java"
                                    iconProps={{ height: 30, width: 30 }}
                                  />
                                }
                              />
                               <SkillItem
                                title="Spring Boot"
                                icon={
                                  <SkillIcon
                                    skill="springboot"
                                    iconProps={{ height: 30, width: 30 }}
                                  />
                                }
                              /> */}
                              <SkillItem
                                title="Rest api"
                                icon={null}
                                className="justify-center"
                              />
                              <SkillItem
                                title="Graphql api"
                                icon={
                                  <SkillIcon
                                    skill="graphql"
                                    iconProps={{ height: 30, width: 30 }}
                                  />
                                }
                              />
                              <SkillItem
                                title="Scikit-learn"
                                icon={
                                  <FontAwesomeIcon
                                    icon={faPython}
                                    className="flex items-center justify-center"
                                    style={{
                                      fontSize: "25px",
                                      transition: "color 0.2s ease-in-out",
                                      color: "var(--secondary-color)",
                                    }}
                                  />
                                }
                              />
                              <SkillItem
                                title="Tensorflow"
                                icon={
                                  <SkillIcon
                                    skill="tensorflow"
                                    iconProps={{ height: 30, width: 30 }}
                                  />
                                }
                              />
                              <SkillItem
                                title="NLTK"
                                icon={
                                  <FontAwesomeIcon
                                    icon={faPython}
                                    className="flex items-center justify-center"
                                    style={{
                                      fontSize: "25px",
                                      transition: "color 0.2s ease-in-out",
                                      color: "var(--secondary-color)",
                                    }}
                                  />
                                }
                              />
                              <SkillItem
                                title="Docker"
                                icon={
                                  <SkillIcon
                                    skill="docker"
                                    iconProps={{ height: 30, width: 30 }}
                                  />
                                }
                              />
                              <SkillItem title="F5 bigip" icon={null} />
                              <SkillItem
                                title="Kubernetes"
                                icon={
                                  <SkillIcon
                                    skill="k8s"
                                    iconProps={{ height: 30, width: 30 }}
                                  />
                                }
                              />
                              <SkillItem
                                title="Terraform"
                                icon={
                                  <SkillIcon
                                    skill="terraform"
                                    iconProps={{ height: 30, width: 30 }}
                                  />
                                }
                              />
                              <SkillItem
                                title="Ansible"
                                icon={
                                  <SkillIcon
                                    skill="ansible"
                                    iconProps={{ height: 30, width: 30 }}
                                  />
                                }
                              />
                              <SkillItem title="Gitlab CI" icon={null} />
                              <SkillItem title="Jenkins" icon={null} />
                            </div>
                          </div>
                          <div className="col-12 col-md-12 py-0 py-md-3">
                            <h3
                              className="heading-default__small opacity is-on"
                              data-viewport="opacity"
                            >
                              Other skills
                            </h3>
                            <div className="grid md:grid-cols-4 md:gap-3 grid-cols-2 mb-10 gap-2 ">
                              <SkillItem
                                title="Scrum Agile"
                                icon={null}
                                className="justify-center"
                              />
                              <SkillItem
                                title="Problem Solving"
                                className="w-[160px]"
                                icon={
                                  <SkillIcon
                                    skill="problem_solving"
                                    iconProps={{ height: 30, width: 30 }}
                                  />
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
      />
    </div>
  );
};

export default Skills;
