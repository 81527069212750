/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from "react";
import { PrismicImage, useAllPrismicDocumentsByType } from "@prismicio/react";

const Urumi = () => {
  const [documents] = useAllPrismicDocumentsByType("urumi");

  useEffect(() => {}, [documents]);
  return (
    <div
      style={{
        flex: 1,
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        rowGap: "10px",
        paddingTop: "50px",
      }}
    >
      <p
        style={{ color: "rgb(127, 164, 186)" }}
        className="font-extrabold text-5xl py-10 pt-0 animate-degree_title"
      >
        Urumi: A Feline Ode to Time and Poetry
      </p>
      <p
        style={{ color: "black", width: "1000px" }}
        className="text-justify	 font-aubrey animate-degree_title hidden sm:block"
      >
        Urimi is not just a pet; he&apos;s family. His name holds a special
        meaning. &quot;U&quot; represents the year of his birth, while
        &quot;Rumi&quot; is a tribute to the great poet Rumi, who crafted verses
        that resonate with the passage of time and the beauty of life. This
        charming purebred European feline has striking almond-shaped blue eyes
        and a tiger coat. His physical beauty is only one part of what makes him
        special. Urimi is known for his playful antics. He is always up for a
        game of chase. When he&apos;s not napping in my office (the place he
        likes the most), Urimi enjoys pouncing on his feathered toy, and he has
        a particular fascination with chasing laser pointers.
      </p>
      {documents?.map((document, key) => (
        <PrismicImage
          key={Number(key)}
          field={document.data.image}
          style={{ borderRadius: "20px" }}
          className="animate-degree_title"
        />
      ))}
    </div>
  );
};

export default Urumi;
